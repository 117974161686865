import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import sty from "./ExhibitionTable.module.scss";

const ExhibitionTable = ({ children }) => {
  return (
    <div className={sty.ExhibitionTable}>
      ExhibitionTable!
      {children}
    </div>
  );
};

ExhibitionTable.propTypes = {};

export default ExhibitionTable;
